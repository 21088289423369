.overlay {
  fill: none;
  pointer-events: all;
}

.focusHidden {
  display: none;
  fill: none;
}

.focusShow {
  display: block;
  fill: black;
}


.streamQualityTooltip
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--background-color-2);
  color: var(--text-color);
  width: 100%;
  padding: 2%;
}